	
<template>
  <div v-if="loaded" class="sponsored">
    <p class="title">{{ fields.title }}</p>
    <prismic-image :field="fields.logo" />
  </div>
</template>
 
<script>
export default {
  name: "PRISMIC",
  props: ["tourId"],
  data() {
    return {
      loaded: false,
      fields: {
        title: null,
        logo: null,
      },
    };
  },
  methods: {
    getContent() {
      this.$prismic.client
        .getByUID("	sponsored_content", this.tourId)
        .then((document) => {
          if (document) {
            this.fields.title = document.data.title;
            this.fields.logo = document.data.banner_image_mobile;
            this.loaded = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style lang="scss" scoped>
.sponsored {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  .title {
    font-size: 9px;
    text-align: center;
    letter-spacing: 0.5px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .sponsored {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    img {
      width: 440px;
    }
    .title {
      font-size: 12px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
</style>

